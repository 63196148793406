/** @jsx jsx */
import { jsx, Box, Container, MenuButton, Flex, Button } from 'theme-ui';
import { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import Sticky from 'react-stickynode';
import Logo from 'components/logo';
import { NavLink } from 'components/link';
// import { Link } from 'components/link';
// import { menuItems, headerNav } from './header.data';

import { headerNav } from './header.data';

export default function Header() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const isHomePage =
    typeof window !== 'undefined' && window.location.pathname === '/';

  const openMobileMenu = () => {
    setMobileMenu(true);
  };

  const closeMobileMenu = () => {
    setMobileMenu(false);
  };

  return (
    <Box sx={styles.headerWrapper}>
      <Sticky enabled={true} top={0} activeClass="is-sticky" innerZ={100}>
        <Box
          as="header"
          variant="layout.header"
          className={mobileMenu ? 'is-mobile-menu' : ''}
          sx={styles.header}
        >
          <Container>
            <Box sx={styles.headerInner}>
              <Logo isWhite={mobileMenu} />

              <Flex
                as="nav"
                sx={styles.navbar}
                className={mobileMenu ? 'navbar active' : 'navbar'}
              >
                <Box
                  as="ul"
                  sx={styles.navList}
                  className={mobileMenu ? 'active' : ''}
                >
                  {headerNav.map(
                    ({ path, label, link, external, target }, i) => {
                      const isHrefLink = external || !isHomePage;

                      return (
                        <li key={i}>
                          {isHrefLink ? (
                            <a
                              href={link}
                              target={target}
                              rel="noopener"
                              className="nav-item"
                              onClick={closeMobileMenu}
                            >
                              {label}
                            </a>
                          ) : (
                            <NavLink
                              path={path}
                              href={link}
                              label={label}
                              target={target}
                              onClick={closeMobileMenu}
                            />
                          )}
                        </li>
                      );
                    }
                  )}
                </Box>
                <a
                  onClick={closeMobileMenu}
                  href="/#pricing"
                  id="cta2"
                  className="btn btn-md cta-2"
                >
                  Subscribe
                </a>
              </Flex>

              {mobileMenu ? (
                <Button variant="text" sx={styles.closeButton}>
                  <GrClose
                    onClick={closeMobileMenu}
                    color="white"
                    size="20px"
                  />
                </Button>
              ) : (
                <MenuButton aria-label="Toggle Menu" onClick={openMobileMenu} />
              )}
            </Box>
          </Container>
        </Box>
      </Sticky>
    </Box>
  );
}

const styles = {
  headerWrapper: {
    backgroundColor: 'rgba(0, 22, 31, 1.0)',
    '.is-sticky': {
      header: {
        backgroundColor: 'rgba(0, 22, 31, 1.0)',
        '::before': {
          boxShadow: '0 6px 13px rgba(38, 78, 118, 0.1)',
          height: '100%',
        },
      },
    },
  },
  header: {
    position: 'relative',
  },
  headerInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media only screen and (max-width: 768px)': {
      '.navbar': {
        position: 'absolute',
        top: '100%',
        backgroundColor: 'rgba(0,22,31,1.0)',
        width: '100%',
        left: 0,
        p: '20px 30px',
        display: 'block',
        boxShadow: '0 6px 13px rgba(38,78,118,0.1)',
        opacity: 0,
        visibility: 'hidden',
        minHeight: 'calc(100vh - 77px)',
        transition: 'all 0.3s ease-in-out 0s',
        '&.active': {
          opacity: 1,
          visibility: 'visible',
        },
        ul: {
          display: 'block',
          'li + li': {
            marginTop: '22px',
          },
          a: {
            color: 'white',
          },
        },
        '.btn': {
          marginTop: '22px',
        },
      },
    },
  },
  navbar: {
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center',
  },
  navList: {
    display: ['flex'],
    color: '#fff',
    listStyle: 'none',
    marginLeft: 'auto',
    a: {
      textDecoration: 'none',
      color: '#fff',
      cursor: 'pointer',
    },
    p: 0,
    '.nav-item': {
      cursor: 'pointer',
      fontWeight: 400,
      padding: 0,
      margin: '0 14px',
    },
    '.active': {
      color: 'primary',
    },
  },

  explore: {
    marginTop: ['24px', '24px', '24px', '24px', '0px'],
  },
  // explore: {
  //   display: ['block', 'block', 'block', 'block', 'none'],
  //   position: 'absolute',
  //   bottom: 40,
  //   left: '50%',
  //   transform: 'translateX(-50%)',
  // },
  closeButton: {
    height: '32px',
    padding: '4px',
    minHeight: 'auto',
    width: '32px',
    ml: '3px',
    path: {
      stroke: '#fff',
    },
  },
};
