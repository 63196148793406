// export const menuItems = [
//   {
//     id: 1,
//     title: 'Company',
//     items: [
//       {
//         path: '#!',
//         label: 'About',
//       },
//       {
//         path: '#!',
//         label: 'Affiliate',
//       },
//       {
//         path: '#!',
//         label: 'Careers & Culture    ',
//       },
//       {
//         path: '#!',
//         label: 'Blog',
//       },
//       {
//         path: '#!',
//         label: 'Press',
//       },
//     ],
//   },
//   {
//     id: 2,
//     title: 'About Us',
//     items: [
//       {
//         path: '#!',
//         label: 'Support Center',
//       },
//       {
//         path: '#!',
//         label: 'Customer Support',
//       },
//       {
//         path: '#!',
//         label: 'About Us',
//       },
//       {
//         path: '#!',
//         label: 'Copyright',
//       },
//       {
//         path: '#!',
//         label: 'Popular Campaign',
//       },
//     ],
//   },
//   {
//     id: 3,
//     title: 'Our Information',
//     items: [
//       {
//         path: '#!',
//         label: 'Return Policy ',
//       },
//       {
//         path: '#!',
//         label: 'Privacy Policy',
//       },
//       {
//         path: '#!',
//         label: 'Terms & Conditions',
//       },
//       {
//         path: '#!',
//         label: 'Site Map',
//       },
//       {
//         path: '#!',
//         label: 'Store Hours',
//       },
//     ],
//   },
//   {
//     id: 4,
//     title: 'My Account',
//     items: [
//       {
//         path: '#!',
//         label: 'Press inquiries',
//       },
//       {
//         path: '#!',
//         label: 'Social media ',
//       },
//       {
//         path: '#!',
//         label: 'directories',
//       },
//       {
//         path: '#!',
//         label: 'Images & B-roll',
//       },
//       {
//         path: '#!',
//         label: 'Permissions',
//       },
//     ],
//   },
//   {
//     id: 5,
//     title: 'Policy',
//     items: [
//       {
//         path: '#!',
//         label: 'Application security',
//       },
//       {
//         path: '#!',
//         label: 'Software principles',
//       },
//       {
//         path: '#!',
//         label: 'Unwanted software policy',
//       },
//       {
//         path: '#!',
//         label: 'Responsible supply chain',
//       },
//     ],
//   },
// ];

export const footerNav = [
  {
    path: 'home-hero',
    label: 'Home',
    link: '/#home-hero',
    external: false,
    isHomepage: true,
  },
  {
    path: 'features',
    label: 'Features',
    link: '/#features',
    external: false,
  },
  {
    path: 'pricing',
    label: 'Pricing',
    link: '/#pricing',
    external: false,
  },
  {
    path: 'guide',
    label: 'Guide',
    link: 'https://guide.moonalgo.com/',
    external: true,
    target: '_blank',
  },
  {
    path: 'faq',
    label: 'FAQ',
    link: '/#faq',
    external: false,
  },
  {
    label: 'Discord',
    link: 'https://discord.gg/S5c7VUAkes',
    external: true,
    target: '_blank',
  },
  {
    label: 'Affiliate Program',
    link: '/affiliate',
    external: true,
    target: '_blank',
  },
];
