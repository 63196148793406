/** @jsx jsx */
import { jsx, Box, Text, Container } from 'theme-ui';
import Logo from 'components/logo';
// import { Link } from 'components/link';
// import FooterWidget from 'components/footer-widget';
// import { menuItems, footerNav } from './footer.data';
import { footerNav } from './footer.data';
import { rgba } from 'polished';

export default function Footer() {
  return (
    <Box as="footer" variant="layout.footer">
      {/* <Container>
        <Box sx={styles.footerTopInner}>
          {menuItems.map(({ id, title, items }) => (
            <FooterWidget key={id} title={title} items={items} />
          ))}
        </Box>
      </Container> */}
      <Container>
        <Box sx={styles.footerInnerDisc}>
          <h5>Disclaimer</h5>
          <p>
            MoonAlgo and its team members are not registered as financial
            advisors and hold no formal qualifications to give financial advice.
            Everything that is provided on this server, on the MoonAlgo website
            or by MoonAlgo and its team members is purely for educational
            purposes only. MoonAlgo and its team members are not accountable or
            liable for any losses or damages. You are responsible for all the
            risks you take. Any content provided here should not be construed as
            financial advice.
          </p>
        </Box>
        <Box className="footer-bottom" sx={styles.footerInner}>
          <Box>
            <Box sx={styles.copyright}>
              <Logo isBlack="true" />
              <Text as="span">
                &nbsp; © {new Date().getFullYear()} MoonAlgo
              </Text>
            </Box>
            <Box>
              <Box as="ul" sx={styles.footerNav}>
                <li>
                  <a
                    href="/terms-and-conditions"
                    className="footer-link"
                    rel="noopener noreferrer"
                    style={{ fontSize: 13 }}
                  >
                    Terms &amp; Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="/privacy-policy"
                    className="footer-link"
                    rel="noopener noreferrer"
                    style={{ fontSize: 13 }}
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="/disclaimer"
                    className="footer-link"
                    rel="noopener noreferrer"
                    style={{ fontSize: 13 }}
                  >
                    Disclaimer
                  </a>
                </li>
              </Box>
            </Box>
          </Box>

          <Box className="mb-1-5" as="ul" sx={styles.footerNav}>
            {footerNav.map(({ label, link, target }, i) => (
              <li key={i}>
                <a
                  href={link}
                  target={target}
                  className="footer-link"
                  rel="noopener noreferrer"
                >
                  {label}
                </a>
              </li>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

const styles = {
  footerTopInner: {
    gap: [50, null, null, null, 17, 50],
    mb: [50],
    display: ['grid'],
    gridTemplateColumns: [
      'repeat(2, 1fr)',
      null,
      null,
      'repeat(3, 1fr)',
      'repeat(5, 1fr)',
    ],
  },
  footerInnerDisc: {
    borderTop: `1px solid #D9E0E7`,
    display: ['block', null, 'block'],
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 0 24px',
    '@media only screen and (max-width: 400px)': {
      pb: 10,
    },
    p: {
      fontSize: '14px',
      lineHeight: '1.5',
    },
  },
  footerInner: {
    borderTop: `1px solid #D9E0E7`,
    display: ['block', null, 'flex'],
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '35px 0 40px',
    '@media only screen and (max-width: 400px)': {
      pb: 10,
    },
  },
  copyright: {
    display: ['flex'],
    alignItems: 'center',
    flexDirection: ['column', null, null, null, 'row'],
    span: {
      fontSize: '14px',
      lineHeight: 1.29,
      color: rgba('#0F2137', 0.6),
      mt: ['18px', '18px', '7px'],
    },
  },
  footerNav: {
    listStyle: 'none',
    margin: ['15px 0 0', '15px 0 0', '0'],
    padding: 0,
    display: ['flex'],
    flexWrap: ['wrap', null, null, 'unset'],
    justifyContent: ['center', null, 'flex-start'],
    'li + li': {
      ml: ['18px', '18px', '20px'],
      '@media only screen and (max-width: 400px)': {
        mb: '10px',
      },
    },
    a: {
      color: 'textSecondary',
    },
  },
};
