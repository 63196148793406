export const headerNav = [
  {
    path: 'home-hero',
    label: 'Home',
    link: '/',
    external: false,
    isHomepage: true,
  },
  // {
  //   path: 'features',
  //   label: 'Features',
  //   link: '/#features',
  //   external: false,
  // },
  {
    path: 'pricing',
    label: 'Pricing',
    link: '/#pricing',
    external: false,
  },
  {
    path: 'guide',
    label: 'Guide',
    link: 'https://guide.moonalgo.com/',
    external: true,
    target: '_blank',
  },
  {
    path: 'faq',
    label: 'FAQ',
    link: '/#faq',
    external: false,
  },
  {
    label: 'Discord',
    link: 'https://discord.gg/S5c7VUAkes',
    external: true,
    target: '_blank',
  },
];
