/** @jsx jsx */
import { jsx, Image } from 'theme-ui';
import { Link } from 'components/link';
import logo from 'assets/images/moonalgo-logo.png';
import logoBlack from 'assets/images/moonalgo-logo-b.png';

export default function Logo({ isBlack }) {
  return (
    <Link
      className={isBlack ? "footer-logo" : "header-logo"}
      path="/"
      sx={{
        variant: 'links.logo',
      }}
    >
      <Image
        className="moonalgo-logo"
        src={isBlack ? logoBlack : logo}
        alt="MoonAlgo logo"
      />
    </Link>
  );
}
