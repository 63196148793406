import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
// import ogImage from 'assets/images/moonalgo-og.png';


function SEO({ description, lang, meta, title, noIndex }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  const location = useLocation();
  const currentUrl = `${site.siteMetadata.siteUrl}${location.pathname}`;

  const metaTitle = title ? `${title} | ${site.siteMetadata.title}` : `${site.siteMetadata.title}`;
  const metaDescription = description ? description : site.siteMetadata.description;
  const ogImageUrl = '/assets/images/moonalgo-og.png?reset=1';

  let robotsMetaTag = null;
  if (noIndex) {
    robotsMetaTag = { name: 'robots', content: 'noindex, nofollow' };
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `title`,
          content: metaTitle,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImageUrl,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: ogImageUrl,
        },
      ]
        .concat(meta)
        .concat(robotsMetaTag ? [robotsMetaTag] : [])} // Concatenate the robots meta tag only if it exists
      link={[
        {
          rel: 'canonical',
          href: currentUrl,
        },
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default SEO;
